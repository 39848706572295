import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Modal } from 'react-bootstrap';

export const App: React.FC = () => {
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [email, setEmail] = useState<string>('');
  const [service, setService] = useState<string>('');
  const [apiLink, setApiLink] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setApiLink('');
    setError(null);
    setIsSubmitting(true);
    

    // Пример API запроса
    const response = await fetch('/api/payments/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, amount, email: email || undefined, service }),
    });
    setIsSubmitting(false);

    if (!response.ok) {
      setError('Произошла ошибка, проверьте введенные данные');
      return;
    }

    const result = await response.json();
    window.open(result.url, '_blank')
    setApiLink(result.url);
  };

  const isFormValid = userId !== undefined && amount !== undefined && (service !== 'freekassa' || email !== '');

  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      {/* <Row className="w-50">
        <img src="path_to_logo.png" alt="Логотип" />
      </Row> */}
      <Row className="w-50">
        <Col>
          <Form onSubmit={handleSubmit} className="bg-light p-4 rounded shadow-sm">
            <Form.Group className="mb-3" controlId="formUserId">
              <Form.Label>Ваш ID <a href="#" onClick={handleShowModal}>Где его найти?</a></Form.Label>
              <Form.Control
                type="number"
                value={userId || ''}
                onChange={(e) => setUserId(Number(e.target.value))}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formAmount">
              <Form.Label>Сумма пополнения</Form.Label>
              <Form.Control
                type="number"
                value={amount || ''}
                onChange={(e) => setAmount(Number(e.target.value))}
              />
            </Form.Group>

            {service === 'freekassa' && (
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Ваша электронная почта</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            )}

            <fieldset className="mb-3">
              <Form.Group>
                <Form.Label as="legend">Выберите сервис</Form.Label>
                <Form.Check
                  type="radio"
                  label="AnyPay (перевод на карту, криптовалюта, моб. операторы)"
                  name="service"
                  id="anypay"
                  value="anypay"
                  onChange={(e) => setService(e.target.value)}
                />
                <Form.Check
                  type="radio"
                  label="LAVA (СБП)"
                  name="service"
                  id="lava"
                  value="lava"
                  onChange={(e) => setService(e.target.value)}
                />
                <Form.Check
                  type="radio"
                  label="Kassa (оплата картой) "
                  name="service"
                  id="freekassa"
                  value="freekassa"
                  onChange={(e) => setService(e.target.value)}
                />
                <Form.Check
                  type="radio"
                  label="Skinsback (оплата скинами CS2, Dota 2, Rust)"
                  name="service"
                  id="skinsback"
                  value="skinsback"
                  onChange={(e) => setService(e.target.value)}
                />
              </Form.Group>
            </fieldset>

            <Button variant="primary" type="submit" disabled={!isFormValid || isSubmitting}>
              {isSubmitting ? 'Обработка...' : 'Пополнить'}
            </Button>
          </Form>
          {isSubmitting && <p>Запрос обрабатывается...</p>}

          {apiLink && (
            <a href={apiLink} className="btn btn-success mt-3 d-block">
              Ссылка для оплаты
            </a>
          )}
          {error && <p className="text-danger">{error}</p>}

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Где мне найти мой ID</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img src="example.png" alt="Личный кабинет" className="img-fluid" />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Закрыть
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};
